import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Footer = () => {

  const { ref, inView } = useInView({
    threshold: 0.2, // Процент видимости, который нужно достичь, чтобы считать элемент видимым
    triggerOnce: 1
  });


  const gridAnimation = {
    initial: { height: 0}, 
    animate: { height: inView ? '100%' : 0},
  };

  const fadeAnimation = {
    initial: { opacity: 0}, 
    animate: { opacity: inView ? 1 : 0},
  };


  return (
    <footer id="footer" className="footer-section">
      <div className='row-grid' ref={ref}>
        <motion.div className='item' {...gridAnimation} transition={{ delay: .3, duration: 1,}}></motion.div>
        <motion.div className='item' {...gridAnimation} transition={{ delay: .7, duration: 1,}}></motion.div>
        <motion.div className='item' {...gridAnimation} transition={{ delay: 1.1, duration: 1,}}></motion.div>
      </div>

      <div className='row-container'>
        <div className='footer-top'>
          <div className='col left'>
            <motion.div className='info' {...fadeAnimation}  transition={{ delay: 1.5, duration: 0.5,}}>
              <ul>
                <li>
                  <div className='icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="26" viewBox="0 0 19 26" fill="none">
                      <path d="M9.04167 25.8333C8.74028 25.8333 8.48195 25.7472 8.26667 25.575C8.05139 25.4028 7.88993 25.1767 7.78229 24.8969C7.37326 23.6913 6.8566 22.5611 6.23229 21.5063C5.62951 20.4514 4.77917 19.2135 3.68125 17.7927C2.58333 16.3719 1.68993 15.0156 1.00104 13.724C0.333681 12.4323 0 10.8715 0 9.04167C0 6.52292 0.871875 4.39167 2.61562 2.64792C4.3809 0.882638 6.52292 0 9.04167 0C11.5604 0 13.6917 0.882638 15.4354 2.64792C17.2007 4.39167 18.0833 6.52292 18.0833 9.04167C18.0833 11.0007 17.7066 12.6368 16.9531 13.95C16.2212 15.2417 15.3708 16.5226 14.4021 17.7927C13.2396 19.3427 12.3569 20.6344 11.7542 21.6677C11.1729 22.6795 10.6885 23.7559 10.301 24.8969C10.1934 25.1983 10.0212 25.4351 9.78438 25.6073C9.5691 25.758 9.32153 25.8333 9.04167 25.8333ZM9.04167 12.2708C9.94583 12.2708 10.7101 11.9587 11.3344 11.3344C11.9587 10.7101 12.2708 9.94583 12.2708 9.04167C12.2708 8.1375 11.9587 7.37326 11.3344 6.74896C10.7101 6.12465 9.94583 5.8125 9.04167 5.8125C8.1375 5.8125 7.37326 6.12465 6.74896 6.74896C6.12465 7.37326 5.8125 8.1375 5.8125 9.04167C5.8125 9.94583 6.12465 10.7101 6.74896 11.3344C7.37326 11.9587 8.1375 12.2708 9.04167 12.2708Z" fill="#D18945"/>
                    </svg>
                  </div>
                  <div className='text-wrap'>
                    <strong>Адрес:</strong>
                    <p>г.Астана, рн. Байконыр переулок Елек, 12/2</p>
                    <a href='https://2gis.kz/astana/geo/70000001086919950/71.376892,51.207804' className='gis'>Открыть в 2GIS</a>
                  </div>
                </li>

                <li>
                  <div className='icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M18.9994 14.4765V17.1862C19.0005 17.4377 18.9488 17.6867 18.8479 17.9172C18.7469 18.1477 18.5988 18.3546 18.413 18.5247C18.2273 18.6947 18.008 18.8242 17.7693 18.9048C17.5305 18.9854 17.2775 19.0153 17.0265 18.9927C14.2415 18.6907 11.5664 17.7409 9.21601 16.2197C7.02929 14.8329 5.17534 12.9827 3.7858 10.8003C2.25627 8.44389 1.30442 5.76107 1.00735 2.96915C0.98473 2.71938 1.01447 2.46764 1.09468 2.22996C1.17489 1.99229 1.30381 1.77389 1.47323 1.58866C1.64265 1.40343 1.84885 1.25544 2.07872 1.15411C2.30858 1.05278 2.55707 1.00032 2.80837 1.00009H5.52347C5.96269 0.995773 6.3885 1.151 6.72152 1.43683C7.05455 1.72267 7.27207 2.11961 7.33354 2.55366C7.44814 3.42084 7.66067 4.27229 7.96707 5.09177C8.08883 5.41507 8.11519 5.76642 8.043 6.1042C7.97082 6.44198 7.80313 6.75203 7.5598 6.99761L6.41041 8.14473C7.69877 10.406 9.57482 12.2784 11.8406 13.5642L12.99 12.4171C13.2361 12.1742 13.5467 12.0069 13.8852 11.9348C14.2236 11.8628 14.5757 11.8891 14.8996 12.0106C15.7207 12.3164 16.5739 12.5285 17.4428 12.6429C17.8824 12.7048 18.2839 12.9258 18.5709 13.2639C18.858 13.6019 19.0105 14.0335 18.9994 14.4765Z" fill="#D18945" stroke="#D18945" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div className='text-wrap'>
                    <strong>Телефон:</strong>
                    <a href='tel:+77072123457' className='tel'>+7 (707) 212-34-57</a>
                    <a href='tel:+77079649699' className='tel'>+7 (707) 964-96-99</a>
                  </div>
                </li>

                <li>
                  <div className='icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                      <path d="M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM9 2.25C12.735 2.25 15.75 5.265 15.75 9C15.75 12.735 12.735 15.75 9 15.75C5.265 15.75 2.25 12.735 2.25 9C2.25 5.265 5.265 2.25 9 2.25ZM7.875 4.5V9.495L8.235 9.7875L9.36 10.9125L10.125 11.7675L11.745 10.1475L10.89 9.3825L10.125 8.6175V4.545H7.875V4.5Z" fill="#EB994C"/>
                    </svg>
                  </div>
                  <div className='text-wrap'>
                    <strong>График работы:</strong>
                    <p>Ежедневно: 10:00 - 20:00</p>
                  </div>
                </li>

              </ul>
            </motion.div>
            <motion.div className='see-you-soon' {...fadeAnimation}  transition={{ delay: 1.7, duration: 0.5,}}>
              <h3>Смотрите так-же</h3>
              
              <div className='additional-services'>
                <a href="/dushevie-peregorodki/">
                  <img src='./душевые-перегородки.jpg' alt="Душевые перегородки в Астане" />
                </a>
                <a href="https://wa.me/p/9227000787352062/77072123457">
                  <img src='./премиум-гардеробные.jpg' alt="Гардеробы в Астане" />
                </a>
              </div>
            </motion.div>
          </div>
          <motion.div className='col right' {...fadeAnimation}  transition={{ delay: 2, duration: 0.5,}}>
            <div className='text-wrap'>
              <h3>Следите за нашей работой в Instagram</h3>
              <p>Подписаться на аккаунт:</p>
              <a href='https://www.instagram.com/alyans_zhihaz/' className='btn'>@alyans_zhihaz</a>
            </div>
            <div className='image'>
              <img src='./images/footer-instagram.png' alt='Стеклянные перегородки в Астане'/>
            </div>
          </motion.div>
        </div>
        <motion.div className='footer-bottom' {...fadeAnimation}  transition={{ delay: 2.4, duration: 0.5,}}>
          <div className='col logo'>
            <img src='./images/logo.png' alt='Alyans Zhihaz - Делаем стеклянные перегородки в Астане' />
          </div>
          <div className='col desc'>
            <p><strong>10 лет создаем стеклянные перегородки</strong> для вашего интерьера</p>
          </div>
          <div className='col copyright'>
            <strong>© Alyans Zhihaz 2024</strong>
            <p>Все права защищены</p>
          </div>
          <div className='col developper' title='Code Republic - Разработка сайтов в Казахстане'>
            <a href='https://taplink.cc/coderepublic.kz' title='Code Republic - Разработка сайтов в Казахстане'>
              <span>Разработка сайта</span>
              <img src='./images/cr-logo.png' alt='Code Republic - Разработка сайтов в Казахстане' title='Code Republic - Разработка сайтов в Казахстане' />
            </a>
          </div>
        </motion.div>
      </div>
    </footer>

    
  );
};

export default Footer;
